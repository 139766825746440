import React from "react";
import "./skill-card.css";
function SkillCard({ skill }) {
  return (
   <div className="skill-card">
      <div className="social-icon">
        <img src={skill.icon} className="social-icon" height="70" width="auto"/>
      </div>
     <label className="skill-name">{skill.name}</label>
   </div>
  );
}
export default SkillCard;