export const ProjectData=[
    {
        id:1,
        title:"Ridewith",
        about: "An Android application to help you find other people to ride share with and save money. Simply put in where you are going and the app will link you up with someone you can share your uber ride with.",
        tags:["Kotlin", "Jetpack Compose", "Dagger Hilt", "MVVM", "Coroutines", "Gradle"],
        website:"https://www.ridewith.app",
        github:"https://github.com/ek868/Ridewith-demo",
        image: require("../../assets/screenshots/ridewith.png"),
    },
    {
        id:2,
        title:"NFT World",
        about:"An NFT based social media platform that produces a feed based on events on the blockchain. Users can follow their favourite influencers, track interesting NFTs, and like events to make them more visible. Users can even challenge one another to contests called \"NFT Battles\" where two NFTs are compared side-by-side and other users can vote on which is cooler. The winner of an NFT-Battle gets a boost in the algorithm and appears in users' feeds as the victor.",
        tags:["Python", "SQL", "Object-Oriented Programming", "API Integration"],
        github: "https://github.com/unsw-cse-comp3900-9900-22T3/capstone-project-comp3900-m10a-coffeecult",
        image: require("../../assets/screenshots/nftworld.png")
    },
    {
        id:3,
        title:"Slackr",
        about:"A communications platform similar to slack. Users can sign up, login, reset their password. Users can create and join private and public channels, send, react to, and pin messages, send timed messages, and search for messages. Users have different privelage levels. Special 'standup' feature where for a period of 15 minutes each user sends a message detailing their standup for the day, and the messages are collated into one for easy reading.",
        tags:["Python", "Object-Oriented Programming"],
        github: "https://github.com/Matt-Galinski/comp1531"
    },
    {
        id:4,
        title:"Dungeon Game",
        about:"A pixelated video game that generates levels given some JSON input. The level can have enemies, weapons, boulders and buttons (for puzzel levels), time slowing potions, invincibility potions, and more.",
        tags:["Java", "JavaFX", "Object-Oriented Programming"],
        github: "https://github.com/ek868/Dungeon_Game"
    }
    // {
    //     id:1,
    //     title:"Reponsive Portfolio",
    //     about: "This is a ramdom project I am using to React.JS,Javascript, HTML/CSS.This is portfolio website and I hope you are enjoying this portfolio.Make sure you like and comment if you have any doubt",
    //     tags:["ReactJS", "Javascript", "Firebase", "Portfolio"],
    //     demo:"https://github.com/ThantZinPhyo-OP/Portfolio-Website",
    //     github:"https://github.com/ThantZinPhyo-OP/Portfolio-Website",
    //     image:"https://github.com/ThantZinPhyo-OP/Portfolio-Website/raw/main/home.png",
    // },
];
