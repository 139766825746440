import React from "react";
import "./education-card.css";
function EducationCard ({ item }) {
  return (
    <div className="education-card">
      <img src={item.image}className="education-logo" />
      <div className="education-info">
        <label className="institution-name">{item.institution}</label>
        <div className="dates">
          <label>{item.start}</label>-<label>{item.finish}</label>
        </div>
        <div className="education-details">
            <label>{item.degree}</label>
        </div>
        <div>
            <label>{item.grade}</label>
        </div>
        {/* <div className="work-desc">
          <p>{item.work}</p>
        </div> */}
      </div>
    </div>
  );
}

export default EducationCard;