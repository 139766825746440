export const WorkData = [
    {
        company: "Bala Technologies" ,
        designation: "Android Developer",
        dateJoining: "December, 2021",
        dateEnd: "Present",
        companyLogo: require("../../assets/icons/ridewith.png"),
        work:"Created slick Android application using Kotlin and Jetpack Compose.",
    },
    {
        company: "Pozy" ,
        designation: "Wix Web Developer",
        dateJoining: "December, 2020",
        dateEnd: "September, 2021",
        companyLogo:require("../../assets/icons/pozy.png"),
        work:"Designed and implemented consumer facing retail site for a local business using Wix. Currently in use."
    },
    {
        company: "Message Train" ,
        designation: "Work Placement",
        dateJoining: "2017",
        dateEnd: " ",
        companyLogo:require("../../assets/icons/messagetrain.jpg"),
        work:"Peer programming for debugging core software. Editted HTML for local business' site. Tested training software on several devices. Helped with video editting."
    }
]