export const SocialData=[
    {
        platform:"Facebook",
        icon: require("../../assets/icons/facebook.png"),
        link:"https://www.facebook.com/eilia.keyhanee.90/",
    },
    {
        platform:"LinkedIn",
        icon: require("../../assets/icons/linkedin.png"),
        link:"https://www.linkedin.com/in/eilia-keyhanee-553484143/",
    },
    {
        platform:"WhatsApp",
        icon: require("../../assets/icons/whatsapp.png"),
        link:"https://wa.me/+61435162957",
    },
    {
        platform:"Github",
        icon: require("../../assets/icons/github.png"),
        link:"https://github.com/ek868",
    },
    {
        platform:"HackerRank",
        icon: require("../../assets/icons/hackerrank.png"),
        link:"https://www.hackerrank.com/eilia_key",
    },
    {
        platform:"LeetCode",
        icon: require("../../assets/icons/leetcode.png"),
        link:"https://leetcode.com/Eiliak/",
    }
    
];