export const SkillsData = [
  {
    type: "Front-End",
    list: [
      {
        name: "Jetpack Compose",
        icon: require("../../assets/icons/compose.png")
      },
      {
        name: "XML",
        icon: require("../../assets/icons/xml.png")
      },
    ],
  },
  {
    type: "Backend",
    list: [
      {
        name: "Kotlin",
        icon: require("../../assets/icons/kotlin.png")
      },
      {
        name: "Python",
        icon: require("../../assets/icons/python.png")
      },
      {
        name: "C/C++",
        icon: require("../../assets/icons/cpp.png")
      },
      {
        name: "Java",
        icon: require("../../assets/icons/java.png")
      },
      {
        name: "SQL",
        icon: require("../../assets/icons/sql.png")
      },
    ],
  },
];