export const EducationData=[
    {
        id:1,
        institution:"University of New South Wales",
        degree:"Bachelor of Computer Science",
        start:"February, 2019",
        finish:"May, 2023",
        grade:"Distinction",
        image: require("../../assets/icons/unsw.png"),
    }
];